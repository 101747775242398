import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import BadgeIcon from '@mui/icons-material/Badge';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TuneIcon from '@mui/icons-material/Tune';
import GradingIcon from '@mui/icons-material/Grading';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WorkIcon from '@mui/icons-material/Work';
import styles from './Navigation.module.css';
import { Divider } from '@mui/material';
import WorkspaceList from './WorkspaceList';
import { useSelector } from 'react-redux';
const Navigation = () => {
  const linkClass = ({ isActive }) => {
    if (isActive) {
      return styles.navLinks + ' ' + styles.navLinkActive;
    } else {
      return styles.navLinks;
    }
  };

  const isProfileHidden = useSelector(
    (state) =>
      state?.loginUserData?.user?.userDetails?.workspace?.hideProfile
  );

  return (
    <>
      <WorkspaceList></WorkspaceList>
      <Divider sx={{ margin: '10px 0px' }} />
      <NavLink to={'/dashboard'} className={linkClass}>
        <HomeIcon className={styles.navIcons} />
        {'Home'}
      </NavLink>
      {!isProfileHidden && (
        <NavLink to={'/profile'} className={linkClass}>
          <BadgeIcon className={styles.navIcons} />
          {'Profile'}
        </NavLink>
      )}
      <NavLink to={'/careerpathway'} className={linkClass}>
        <TrendingUpIcon className={styles.navIcons} />
        {'Career Pathways'}
      </NavLink>
      <NavLink to={'/jobCenter'} className={linkClass}>
        <WorkIcon className={styles.navIcons} />
        {'Job Center'}
      </NavLink>
      <NavLink to={'/businessIdeas'} className={linkClass}>
        <EditNoteIcon className={styles.navIcons} />
        {'Business Ideas'}
      </NavLink>
      <NavLink to={'/research'} className={linkClass}>
        <ManageSearchIcon className={styles.navIcons} />
        {'Research'}
      </NavLink>
      <NavLink to={'/filter'} className={linkClass}>
        <TuneIcon className={styles.navIcons} />
        {'Filter'}
      </NavLink>
      <NavLink to={'/businessplan'} className={linkClass}>
        <GradingIcon className={styles.navIcons} />
        {'Business Plan'}
      </NavLink>
      <NavLink to={'/pro'} className={linkClass}>
        <AttachMoneyIcon className={styles.navIcons} />
        {'Pro'}
      </NavLink>
    </>
  );
};

export default Navigation;
