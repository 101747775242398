import DashboardLayout from '../Layouts/DashboardLayout';
import JobCenterComp from './JobCenterComp';

const JobCenterContainer = () => {
  return (
    <DashboardLayout>
      <JobCenterComp></JobCenterComp>
    </DashboardLayout>
  );
};
export default JobCenterContainer;