import { useSelector, useDispatch } from 'react-redux';
import { getJobs } from '../../Services/jobCenterServices';
import { useState } from 'react';
import { Box, TextField, Button, Typography, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { jobCenter } from '../../data/constants';

const JobCenterComp = () => {
  const dispatch = useDispatch();
  const jobCenterData = useSelector(
    (state) => state?.jobCenterData?.jobCenterData
  );
  const [jobTitle, setJobTitle] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const getFilteredJobs = async () => {
    let queryParams;
    queryParams = `&what=${jobTitle}&where=${jobLocation}`;
    await getJobs(queryParams, dispatch);
  }
  return (
    <>
      <Box mt={2} ml={2} mr={2} pb={3}>
        <Typography component={'h1'} variant="dashboardCompHeading">
          {jobCenter.title}
        </Typography>
        <Box className='displayFlex alignItemsCenter width50 justifySelfCenter border1 padding1 borderRadius15 boxShadow borderColorGrey justifySpaceBetween'>
          <Box className='displayFlex alignItemsCenter justifyCenter'>
            <SearchIcon fontSize='medium' sx={{ color: '#736c6c' }} />
            <TextField
              className='textFieldType6'
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              placeholder='Job Profile'
            />
          </Box>
          <Divider orientation="vertical" variant="fullWidth" flexItem />
          <Box className='displayFlex alignItemsCenter justifyCenter'>
            <LocationOnIcon fontSize='medium' sx={{ color: '#736c6c' }} />
            <TextField
              className='textFieldType6'
              value={jobLocation}
              onChange={(e) => setJobLocation(e.target.value)}
              placeholder='Location'
            />
          </Box>
          <Box>
            <Button
              className="textTrasformCapital"
              variant="buttonVariant1"
              onClick={getFilteredJobs}
            >
              {jobCenter.findBtn}
            </Button>
          </Box>
        </Box>
        <Box className="mt3">
          {jobCenterData ? (
            jobCenterData.map((job) => (
              <Box key={job.id} className="mt2 border1 borderColorGrey borderRadius15 boxShadow padding1x2x2x2rem color60_60_60">
                <h2>{job.title}</h2>
                <Divider orientation="horizontal" variant="fullWidth" flexItem />
                <p>{job.company.display_name}<br />{job.location.display_name}, {job.location.area[1]}, {job.location.area[0]}</p>
                <p>{job.description} <a href={job.redirect_url} target="_blank" rel="noopener noreferrer">
                  {jobCenter.readMore}
                </a></p>
                <p><strong>Salary Range:</strong> ${job.salary_min} - ${job.salary_max}</p>
                <p><strong>Job Type:</strong> {job.category.label}</p>
                <p><strong>Posted On:</strong> {new Date(job.created).toLocaleDateString()}</p>
                <Box>
                  <a href={job.redirect_url} target="_blank" rel="noopener noreferrer">
                    <Button
                      className="textTrasformCapital mt1"
                      variant="buttonVariant1"
                    >
                      {jobCenter.applyNow}
                    </Button>
                  </a>
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              component={'h2'}
              mt={5}
              fontSize={24}
              fontWeight={'bold'}
              textAlign={'center'}
            >
              {jobCenter.filterJobs}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default JobCenterComp;
