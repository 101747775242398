import axios from 'axios';
import { saved, saving, couldnotSave } from '../redux/saveSlice';
import { setJobCenterData } from '../redux/jobCenterSlice';
import ApiConfig from '../config/ApiConfig';
import { STATUS_200, STATUS_300 } from "../data/constants";

const baseURL = process.env.ADZUNA_APP_URL;
const appId = process.env.ADZUNA_APP_ID;
const appKey = process.env.ADZUNA_APP_KEY;

export const getJobs = async (queryParams, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.get(`${ApiConfig.getJobs}?${queryParams}`);
    if (resp?.data.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      dispatch(setJobCenterData(resp?.data?.data?.results));
      return resp?.data?.data?.results;
    } else {
      dispatch(couldnotSave());
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    return false;
  }
};